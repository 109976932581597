import { Phetch } from "@prowise/phetch";
import { Route, Router } from "@solidjs/router";
import { Component, ErrorBoundary, lazy, Show } from "solid-js";
import { config } from "./config";
import {
    AccountManagementIdentifier,
    AccountMemoryIdentifier,
    BasicCredentialsStageIdentifier,
    MultiFactorStageIdentifier,
    PictureLoginStageIdentifier,
    ProviderSelectionStageIdentifier,
    QrCodeStageIdentifier,
    UserLicenceStageIdentifier,
} from "./domain";
import { SessionInitializer, StateRenderer, stateScreens, Test } from "./pages";
import { Apps } from "./pages/Apps";
import { Error } from "./pages/Error";
import "./index.css";

const Redirecter = lazy(async () => import("./components/Redirecter"));

const PrivacyStatement = lazy(async () => import("./pages/PrivacyStatement"));
const QrProvider = lazy(async () => import("./pages/QrProvider"));
const RequestPasswordReset = lazy(async () => import("./pages/RequestPasswordReset"));
const LogoutSuccess = lazy(async () => import("./pages/LogoutSuccess"));
const FinalizeRegisterReset = lazy(async () => import("./pages/FinalizeRegisterReset"));
const AccountRegistration = lazy(async () => import("./pages/AccountRegistration"));
const EmailVerification = lazy(async () => import("./pages/EmailVerification"));
const Logout = lazy(async () => import("./pages/Logout"));
const FourOFour = lazy(async () => import("./pages/404"));
const SessionDebug = lazy(async () => import("./pages/SessionDebug"));

const BasicCredentials = lazy(async () => import("./challenges/BasicCredentials"));
const Password = lazy(async () => import("./challenges/Password"));
const DocumentAccept = lazy(async () => import("./challenges/DocumentAccept"));
const QrLogin = lazy(async () => import("./challenges/QrLogin"));
const AccountMemory = lazy(async () => import("./challenges/AccountMemory"));
const AccountUnknown = lazy(async () => import("./challenges/AccountUnknown"));
const AccountLinking = lazy(async () => import("./challenges/AccountLinking"));
const AccountComplete = lazy(async () => import("./challenges/AccountComplete"));
const ExternalEmailVerification = lazy(async () => import("./challenges/ExternalEmailVerification"));
const MultiFactorTotp = lazy(async () => import("./challenges/MultiFactorTotp"));
const ThirdPartyProviderSelect = lazy(async () => import("./challenges/ThirdPartyProviderSelect"));
const ExternalAuthSchoolSelect = lazy(async () => import("./challenges/ExternalAuthSchoolSelect"));
const ExternalAccountLinking = lazy(async () => import("./challenges/ExternalAccountLinking"));
const ExternalAccountRegistration = lazy(async () => import("./challenges/ExternalAccountRegistration"));
const ExternalAccountRegistrationOrLinking = lazy(async () => import("./challenges/ExternalAccountRegistrationOrLinking"));
const PictureSelect = lazy(async () => import("./challenges/PictureSelect"));
const PictureLogin = lazy(async () => import("./challenges/PictureLogin"));
const UserLicence = lazy(async () => import("./challenges/UserLicence"));

// Include cross-origin cookies (credentials) in debug mode
void new Phetch({ baseUrl: config.baseApi, credentials: config.debug.includeCredentials ? "include" : "same-origin" });

const App: Component = () => (<ErrorBoundary fallback={(err) => <Error exception={err} />}>
    <Show when={window.location.host.startsWith("apps")}>
        <Router explicitLinks={true}>
            <Route path="*" component={Apps}/>
        </Router>
    </Show>
    <Show when={!window.location.host.startsWith("apps")}>
        <Router explicitLinks={true}>
            <Route path="/error" component={() => <Error/>} />
            <Route path="/device" component={QrProvider}/>
            <Route path="/privacy-statement" component={PrivacyStatement}/>
            <Route path="/account-recovery" component={RequestPasswordReset}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/logout/success" component={LogoutSuccess}/>
            <Route path="/account-registration" component={AccountRegistration}/>
            <Route path="/account-finalize" component={FinalizeRegisterReset}/>
            <Route path="/email-verification" component={EmailVerification}/>
            {config.debug.sandbox ? <Route path="test/*" component={Test}/> : null}
            {config.debug.session ? <Route path="/session-debug" component={SessionDebug} /> : null}

            <Route path="/apps" component={Apps}/>
            <Route path="/redirect" component={Redirecter}/>

            <Route path="/" component={StateRenderer}>
                <Route path="/" component={SessionInitializer}/>
                <Route
                    path={stateScreens[BasicCredentialsStageIdentifier.BASIC_CREDENTIALS]}
                    component={BasicCredentials}
                />
                <Route path={stateScreens[BasicCredentialsStageIdentifier.PASSWORD]} component={Password}/>
                <Route path={stateScreens[BasicCredentialsStageIdentifier.DOCUMENT_ACCEPT]}
                    component={DocumentAccept}
                />
                <Route path={stateScreens[QrCodeStageIdentifier.SCAN_QR]} component={QrLogin}/>
                <Route path={stateScreens[AccountMemoryIdentifier.PICK_REMEMBER_USER]} component={AccountMemory}/>
                <Route path={stateScreens[AccountManagementIdentifier.ACCOUNT_LINK]} component={AccountLinking}/>
                <Route path={stateScreens[AccountManagementIdentifier.ACCOUNT_COMPLETE]}
                    component={AccountComplete}
                />
                <Route
                    path={stateScreens[AccountManagementIdentifier.EXTERNAL_EMAIL_VERIFICATION]}
                    component={ExternalEmailVerification}
                />
                <Route
                    path={stateScreens[AccountManagementIdentifier.EXTERNAL_ACCOUNT_LINKING]}
                    component={ExternalAccountLinking}
                />
                <Route
                    path={stateScreens[AccountManagementIdentifier.EXTERNAL_ACCOUNT_REGISTRATION]}
                    component={ExternalAccountRegistration}
                />
                <Route
                    path={stateScreens[AccountManagementIdentifier.EXTERNAL_ACCOUNT_REGISTRATION_OR_LINKING]}
                    component={ExternalAccountRegistrationOrLinking}
                />
                <Route path={stateScreens[AccountManagementIdentifier.ACCOUNT_UNKNOWN]} component={AccountUnknown}/>
                <Route path={stateScreens[MultiFactorStageIdentifier.MFA_TOTP]} component={MultiFactorTotp}/>
                <Route
                    path={stateScreens[ProviderSelectionStageIdentifier.SELECT_AUTH_SOURCE]}
                    component={ThirdPartyProviderSelect}
                />
                <Route
                    path={stateScreens[ProviderSelectionStageIdentifier.SELECT_EXTERNAL_AUTH_SCHOOL]}
                    component={ExternalAuthSchoolSelect}
                />
                <Route path={stateScreens[PictureLoginStageIdentifier.SELECT_USER]} component={PictureSelect}/>
                <Route path={stateScreens[PictureLoginStageIdentifier.PICTURE_LOGIN]} component={PictureLogin}/>
                <Route path={stateScreens[UserLicenceStageIdentifier.NO_LICENCE]} component={UserLicence}/>
            </Route>

            <Route path="*" component={FourOFour}/>
        </Router>
    </Show>
</ErrorBoundary>);

export default App;
