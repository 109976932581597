import { type ChallengeInterface } from "./ChallengeInterface";
import { type RedirectInterface } from "./RedirectInterface";
import { StageType } from "./StageType";

/**
 * Finalization Stage.
 */
export type FinalizeStage = {
    /** Type for finalize. */
    type: StageType.FINALIZE;

    /** URL to redirect to. */
    url: string;
};

/**
 * Exception identifier (code).
 */
export enum ExceptionIdentifier {
    // From JSONError
    /** 500: Server error. */
    UNKNOWN = "UNKNOWN",
    /** 403: Not started. */
    NO_FLOW = "NO_FLOW",

    // Generic fetch and HTTP status errors.
    /** 400: Bad request. */
    HTTP_400 = "HTTP_400",
    /** 401: Unauthorized. */
    HTTP_401 = "HTTP_401",
    /** 403: Forbidden. */
    HTTP_403 = "HTTP_403",
    /** 404: Not found. */
    HTTP_404 = "HTTP_404",
    /** 409: Conflict/Mismatch. */
    HTTP_409 = "HTTP_409",
    /** 422: Unprocessable. */
    HTTP_422 = "HTTP_422",
    /** 429: Too many requests. */
    HTTP_429 = "HTTP_429",
    /** 500: Server error. */
    HTTP_500 = "HTTP_500",
    /** 502: Bad gateway. */
    HTTP_502 = "HTTP_502",
    /** 503: Service unavailable. */
    HTTP_503 = "HTTP_503",
    /** 504: Gateway timeout. */
    HTTP_504 = "HTTP_504",

    /** Fetch exception: TypeError. */
    NETWORK_ERROR = "NETWORK_ERROR",
    /** Fetch exception: DOMException / AbortError. */
    ABORT_ERROR = "ABORT_ERROR",
    /** JSON parse error: SyntaxError. */
    JSON_ERROR = "JSON_ERROR",

    /* Flow errors. */
    /**
     * Any of the inputs that form credentials for the user are invalid,
     * the frontend might show a specific error detailing that login cannot be completed
     * due to invalid credentials and ofter to try again.
     */
    INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
    /**
     * Used when there is only one input, or we don't know which input specifically it is
     * (see INVALID_CREDENTIALS). Frontend should make all fields red/invalid and ask
     * to try again/fix errors.
     */
    INVALID_INPUT = "INVALID_INPUT",
    /**
     * The user has gotten into an invalid state and is being redirected back
     * to a state in which they can fix the issue.
     */
    INVALID_STATE = "INVALID_STATE",
    /**
     * The user is not yet authenticated in any way,
     * they need to complete a challenge to authenticate.
     */
    AUTHENTICATION_REQUIRED = "AUTHN_REQUIRED",

    /** No interaction ID: custom, local error */
    NO_INTERACTION_ID = "NO_INTERACTION_ID",
}

export enum ErrorSeverity {
    "NONE",
    "DEBUG",
    "INFO",
    "WARNING",
    "ERROR",
    "FATAL",
}

/**
 * SSO Error message.
 */
export type StageError = {
    /** The (power) user readable error message. */
    message: string;
    /** The error identifier. */
    id: ExceptionIdentifier;
    /** An optional error reference. */
    reference?: string;
    /**
     *
     */
    severity?: ErrorSeverity;
};

/**
 * A stage represents a step in a flow.
 */
export type Stage = RedirectInterface | ChallengeInterface | FinalizeStage;
